import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { st, classes } from './Widget.st.css';
import { FormControllerActions } from '../Actions/actions';
import { FormViewModel } from '../ViewModel/viewModel';
import BookingDetails from './BookingDetails/BookingDetails';
import { FormActionsProvider } from '../Hooks/useFormActions';
import { FormRef, SubmissionResponse } from '@wix/forms-ui/types';
import FormInputs from './FormInputs/FormInputs';
import { Login } from './Login/Login';
import { FormState } from '../../../utils/state/initialStateFactory';
import { Header } from './Header/Header';

export type FormComponentActions = { submitForm?: () => SubmissionResponse };
export type FormActions = FormControllerActions & FormComponentActions;

export type ControllerProps = {
  actions: FormControllerActions;
} & FormState &
  FormViewModel;

const Widget: FC<WidgetProps<ControllerProps>> = ({
  bookingDetailsViewModel,
  formSchemaViewModel,
  paymentViewModel,
  actions,
  userDetails,
  service,
}) => {
  const formRef = React.useRef<FormRef>();
  const showLogin = !userDetails;

  return (
    <FormActionsProvider
      value={{ ...actions, submitForm: () => formRef?.current?.submit?.() }}
    >
      <div className={st(classes.root, {})}>
        <div className={classes.backButton}>Back</div>
        <div className={classes.body}>
          <div className={classes.formWrapper}>
            <Header {...service.formHeader} />
            {showLogin ? <Login /> : null}
            <FormInputs
              formSchemaViewModel={formSchemaViewModel}
              formRef={formRef}
            />
          </div>
          <div className={classes.sidebar}>
            <BookingDetails
              bookingDetailsViewModel={bookingDetailsViewModel}
              paymentViewModel={paymentViewModel}
            />
          </div>
        </div>
      </div>
    </FormActionsProvider>
  );
};

export default Widget;
