import React from 'react';
import { FormControllerActions } from '../Actions/actions';
import { SubmitForm } from '../Widget/FormInputs/FormInputs';

export const FormActionsContext = React.createContext<
  FormControllerActions & { submitForm?: SubmitForm }
>({} as FormControllerActions);

export const FormActionsConsumer = FormActionsContext.Consumer;
export const FormActionsProvider = FormActionsContext.Provider;

export const useFormActions = () => React.useContext(FormActionsContext);
