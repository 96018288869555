export enum BookingDetailsDataHooks {
  HEADER_TITLE = 'booking-details-header-title',
  DIVIDER = 'booking-details-divider',
  SERVICE_NAME = 'booking-details-service-name',
  SLOT_DATE_AND_TIME = 'booking-details-slot-date-and-time',
  DURATION = 'booking-details-duration',
  STAFF_MEMBER = 'booking-details-staff-member',
  LOCATION = 'booking-details-location',
  VIDEO_CONFERENCE_BADGE = 'booking-details-video-conference-badge',
  ACTION_BUTTON = 'booking-details-action-button',
}
