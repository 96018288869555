import React, { useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  PaymentOption,
  PaymentVariationType,
  PaymentViewModel,
  PriceSummaryType,
} from '../../../ViewModel/PaymentViewModel/paymentViewModel';
import { classes, st } from './Payment.st.css';
import { Text } from 'wix-ui-tpa/Text';
import { TextButton, TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { PaymentDataHooks } from './dataHooks';
import { Divider } from 'wix-ui-tpa/Divider';
import { RadioButtonGroup } from 'wix-ui-tpa/RadioButtonGroup';
import { TextField } from 'wix-ui-tpa/TextField';
import {
  RadioButton,
  RadioButtonTheme,
} from 'wix-ui-tpa/dist/src/components/RadioButton/RadioButton';
import { useFormActions } from '../../../Hooks/useFormActions';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { ReactComponent as Alert } from '../../../../../assets/icons/Alert.svg';
import { ReactComponent as PromoTag } from '../../../../../assets/icons/PromoTag.svg';
import { ReactComponent as PromoCodeArrow } from '../../../../../assets/icons/PromoCodeArrow.svg';

export const ENTER = 'enter';
export interface PaymentProps {
  viewModel: PaymentViewModel;
}
const Payment: React.FC<PaymentProps> = ({
  viewModel,
}: {
  viewModel: PaymentViewModel;
}) => {
  return (
    <div className={st(classes.root)}>
      {shouldDisplayPaymentSection(viewModel) ? (
        <div data-hook={PaymentDataHooks.PAYMENT_WRAPPER}>
          <PaymentTitle />
          <PaymentOptions viewModel={viewModel} />
          <Warning viewModel={viewModel} />
          <PromoCode viewModel={viewModel} />
          <PriceSummary viewModel={viewModel} />
        </div>
      ) : null}
    </div>
  );
};

const PromoCode: React.FC<PaymentProps> = ({
  viewModel,
}: {
  viewModel: PaymentViewModel;
}) => {
  const { setCoupon } = useFormActions();
  const [showPromoCodeInput, setShowPromoCodeInput] = useState(false);

  const togglePromoCodeInput = () => {
    setShowPromoCodeInput(!showPromoCodeInput);
  };

  return viewModel.promoCodeText ? (
    <div>
      <Divider
        className={classes.divider}
        data-hook={PaymentDataHooks.DIVIDER}
      />
      <TextButton
        priority={TEXT_BUTTON_PRIORITY.link}
        className={classes.marginBottom}
        data-hook={PaymentDataHooks.PROMO_CODE_TEXT_BUTTON}
        onClick={() => togglePromoCodeInput()}
        prefixIcon={<PromoTag />}
      >
        {viewModel.promoCodeText}
      </TextButton>
      {showPromoCodeInput ? (
        <TextField
          data-hook={PaymentDataHooks.PROMO_CODE_INPUT}
          suffix={<PromoCodeArrow />}
          onKeyDown={(e) =>
            e.key.toLowerCase() === ENTER
              ? setCoupon(e.currentTarget.value)
              : null
          }
        />
      ) : null}
      <Divider
        className={classes.divider}
        data-hook={PaymentDataHooks.DIVIDER}
      />
    </div>
  ) : null;
};

const PaymentOptions: React.FC<PaymentProps> = ({
  viewModel,
}: {
  viewModel: PaymentViewModel;
}) => {
  const { setPaymentOptionAction } = useFormActions();

  const paymentOptionLabel = (paymentOption: PaymentOption) => {
    return viewModel.paymentOptions && viewModel.paymentOptions.length > 1 ? (
      <div className={classes.paymentOptionsSpace}>
        <div>
          <Text className={classes.primaryText}>{paymentOption.label}</Text>
          {paymentOption.validUntil ? (
            <Text className={classes.secondaryText}>
              {paymentOption.validUntil}
            </Text>
          ) : null}
        </div>
        {paymentSuffix(paymentOption)}
      </div>
    ) : null;
  };

  const paymentSuffix = (paymentOption: PaymentOption) => {
    return (
      <Text className={classes.secondaryText}>{paymentOption.suffix}</Text>
    );
  };

  const shouldDisplayPaymentOptions = () =>
    viewModel.paymentOptions && viewModel.paymentOptions.length > 1;

  const isSingleSessionPrice = () =>
    viewModel.paymentOptions &&
    viewModel.paymentOptions[0]?.paymentType !== PaymentVariationType.Fixed;

  return shouldDisplayPaymentOptions() ? (
    <>
      <RadioButtonGroup
        name="paymentOptions"
        withSpacing
        theme={RadioButtonTheme.Box}
        onChange={(paymentOption) => setPaymentOptionAction(paymentOption)}
        className={st(classes.paymentOptions)}
        data-hook={PaymentDataHooks.PAYMENT_OPTIONS}
        value={viewModel.paymentOptions?.find((option) => option.checked)?.id}
      >
        {viewModel.paymentOptions?.map((paymentOption, i) => {
          return (
            <RadioButton
              data-hook={PaymentDataHooks.PAYMENT_OPTION + '-' + i}
              key={paymentOption.id}
              value={paymentOption.id!}
              children={paymentOptionLabel(paymentOption)}
              disabled={paymentOption.disabled}
            />
          );
        })}
      </RadioButtonGroup>
    </>
  ) : isSingleSessionPrice() ? (
    <>
      <div className={classes.membership}>
        <Text
          data-hook={PaymentDataHooks.MEMBERSHIP}
          className={st(classes.secondaryText)}
        >
          {viewModel?.paymentOptions?.[0]?.label}
        </Text>
        {viewModel?.paymentOptions?.[0]?.suffix ? (
          <Text
            data-hook={PaymentDataHooks.MEMBERSHIP_CREDIT}
            className={st(classes.secondaryText)}
          >
            {viewModel?.paymentOptions?.[0]?.suffix}
          </Text>
        ) : null}
      </div>
      <div>
        <Text
          data-hook={PaymentDataHooks.MEMBERSHIP_VALIDATION}
          className={st(classes.secondaryText)}
        >
          {viewModel?.paymentOptions?.[0]?.validUntil}
        </Text>
      </div>
    </>
  ) : null;
};

const PaymentTitle: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={st(classes.marginBottom)}>
      <Divider
        className={classes.divider}
        data-hook={PaymentDataHooks.DIVIDER}
      />
      <Text data-hook={PaymentDataHooks.TITLE} className={classes.primaryText}>
        {t('app.settings.text.form.payment.title')}
      </Text>
    </div>
  );
};

const Warning: React.FC<PaymentProps> = ({
  viewModel,
}: {
  viewModel: PaymentViewModel;
}) => {
  const { t } = useTranslation();
  return viewModel.warning ? (
    <SectionNotification type="alert" data-hook={PaymentDataHooks.WARNING}>
      <SectionNotification.Icon icon={<Alert />} />
      <SectionNotification.Text className={st(classes.primaryText)}>
        {t(viewModel.warning)}
      </SectionNotification.Text>
    </SectionNotification>
  ) : null;
};

const PriceSummary: React.FC<PaymentProps> = ({
  viewModel,
}: {
  viewModel: PaymentViewModel;
}) => {
  const summaryDataHook = (summaryType: PriceSummaryType) => {
    switch (summaryType) {
      case PriceSummaryType.CustomPrice:
        return PaymentDataHooks.CUSTOM_PRICE;
      case PriceSummaryType.SingleSession:
        return PaymentDataHooks.SUMMARY_SINGLE_SESSION;
      case PriceSummaryType.Total:
        return PaymentDataHooks.TOTAL;
    }
  };
  return viewModel.priceSummary ? (
    <>
      <div data-hook={PaymentDataHooks.PRICE_SUMMARY}>
        {viewModel.priceSummary?.map((summary) => {
          const className =
            summary.type === PriceSummaryType.CouponDiscount ||
            summary.type === PriceSummaryType.SingleSession ||
            summary.type === PriceSummaryType.CustomPrice
              ? classes.secondaryText
              : classes.primaryText;
          return (
            <div className={classes.itemsSpace}>
              <Text
                className={className}
                data-hook={summaryDataHook(summary?.type!)}
              >
                {summary.label}
              </Text>
              {summary.price ? (
                <Text key={summary.label} className={className}>
                  {summary.price}
                </Text>
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  ) : null;
};

const shouldDisplayPaymentSection = (viewModel: PaymentViewModel) => {
  return (
    viewModel.warning ||
    (viewModel.paymentOptions && viewModel.paymentOptions.length > 0) ||
    (viewModel.priceSummary && viewModel.priceSummary.length > 0)
  );
};

export default Payment;
