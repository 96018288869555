import React from 'react';
import { classes } from './FormInputs.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  FormRef,
  FormView,
  FormViewField,
  SubmissionResponse,
} from '@wix/forms-ui/types';
import { Form } from '@wix/forms-ui/tpa';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { FormInputsDataHooks } from './dataHooks';

export interface FormInputsProps {
  formSchemaViewModel: FormView;
  formRef: React.MutableRefObject<FormRef | undefined>;
}

export type SubmitForm = () => SubmissionResponse | undefined;

const FormInputs: React.FC<FormInputsProps> = ({
  formSchemaViewModel,
  formRef,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const translateState = ({
    state,
    context: { field },
  }: {
    state: string;
    context: { field?: FormViewField };
  }) => {
    const type = field?.renderInfo?.type;

    switch (state) {
      case 'INVALID_PATTERN':
        return `The field ${type} has invalid pattern.`;
      case 'VALUE_REQUIRED':
        return `The field ${type} value is required.`;
      case 'VALUE_OUT_OF_RANGE':
        return `The field ${type} value of out range.`;
      default:
        return '';
    }
  };
  return (
    <Form
      ref={formRef}
      className={classes.root}
      data-hook={FormInputsDataHooks.FORM_COMPONENT}
      translateState={translateState}
      theme={settings.get(settingsParams.fieldsBorderStyle)}
      schema={formSchemaViewModel}
      onSubmit={() => {}}
      isMobile={isMobile}
    />
  );
};

export default FormInputs;
